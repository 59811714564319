$fontPath: "~/assets/fonts/";

@font-face {
  font-family: "Open Sans";
  font-weight: 200;
  src: url($fontPath + "OpenSans-Light.ttf") format("truetype")
}

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  src: url($fontPath + "OpenSans-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: url($fontPath + "OpenSans-SemiBold.ttf") format("truetype")
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: italic;
  src: url($fontPath + "OpenSans-SemiBoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  src: url($fontPath + "OpenSans-Bold.ttf") format("truetype")
}

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: italic;
  src: url($fontPath + "OpenSans-BoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  src: url($fontPath + "OpenSans-ExtraBold.ttf") format("truetype")
}
