$body-bg: #F4F4F4;
$font-family-base: arial;
$theme-colors: (
  "primary": #FF8C17,
  "negative": #D50B0F
);

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/bootstrap-reboot";
@import "assets/styles/material";
@import "assets/styles/fonts";
@import "assets/styles/variables";

body {
  font-family: 'Open Sans', Arial, sans-serif;
  color: #000000;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

// this is to add the font everywhere, otherwise some Roboto appears
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', Arial, sans-serif;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: auto;
  height: auto;
  z-index: 1;
}
.always-mobile .spinner-container {
  background: rgba(255, 255, 255, 1);
}

.card {
  height: 100%;
  background-color: white;
  padding: 0;
  border-radius: 10px;
  border: none;
  box-shadow: 2px 2px 6px #00000012;
}

//BUTTONS

.btn:focus, button:focus {
  outline: none;
}

.btn-primary {
  background-color: $primary-color;
  color: white;
  border: none;
  margin-left: 0px;
  padding: 0.5rem 2.6rem;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 0;

  &.mat-mdc-button-disabled {
    background-color: $darken-primary-color;
  }
}
.btn-positive {
  background-color: $positive-color;
  color: white;
  border: none;
  margin-left: 0px;
  padding: 0.5rem 2.6rem;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 0;

  &.mat-mdc-button-disabled {
    background-color: $darken-positive-color;
  }
}
.btn-positive:hover,
.btn-positive:active {
  background-color: $darken-positive-color;
  color: white;
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid black;
  color: black;
  padding: 0.5rem 2.6rem;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 0;
}

.btn-plain {
  background-color: transparent;
  border: none;
  color: $blue-color;
  font-size: 0.8rem;
  font-weight: 600;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #99530e;
  color: white;
}

.btn-primary:disabled,
.btn-secondary:disabled {
  color: $grey-color !important;
  border-color: $grey-color !important;
  background-color: #b7b7b7 !important;
}

.btn-primary:focus,
.btn-secondary:focus,
.mat-mini-fab:focus,
.mat-button:focus {
  outline: none;
}

.btn-secondary:hover,
.btn-secondary:active {
  background-color: transparent;
  color: black;
  border: 1px solid black;
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: $primary-color;
}

//.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
//  font-family: 'Open Sans', Arial, sans-serif ;
//  font-weight: 500;
//  color: white;
//  background-color: $blue-color;
//}

//FORMS

.form-group-single {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(1, 1fr);
}

.form-group-double {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(2, 1fr);
}

.form-group-triple {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(3, 1fr);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $blue-color;
  background-color: $blue-color;
}

.form-control {
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid $light-grey-color;
  border-radius: 0;
  font-size: 0.8rem;
  padding: 0.375rem 1.5rem;
}

.form-control:focus {
  color: black;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $blue-color;
  box-shadow: none;
}

.form-control.is-invalid:focus {
  border: none;
  border-bottom: 1px solid $red-color;
  box-shadow: none;
}

.invalid-feedback {
  position: absolute;
  margin-top: 0;
  line-height: 1.4;
}

.alert {
  margin-bottom: 0;
  border: none;
  position: absolute;
  right: $margin15;
}

// MATERIAL DIALOG

.message-container {
  height: 100%;
}

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 10px !important;
}


.mat-dialog-content {
  font-size: 1.3rem;
  min-height: 125px;
  font-weight: 200;
  text-align: center;
}

.mat-dialog-title {
  font-family: 'Open Sans', Arial, sans-serif;
  text-align: center;
  font-weight: 600;
  min-height: 50px;
  margin: 0 !important;
}

.mat-dialog-actions {
  padding: 0 !important;
  justify-content: space-around;
}

mat-checkbox.text-wrap {
  label.mat-checkbox-layout {
    white-space: normal;
  }
}

.form-error {
  height: 20px;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 5px;
  color: $red-color;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #FA8C17 !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #808080 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.details-box {
  height: calc(100vh - 200px);
}

.app-list {
  height: calc(100vh - 260px);
  overflow: auto;
}
.app-list-responsive {
  contain: none !important;
  overflow: auto !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
  background-color: $blue-color !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
  color: $blue-color !important;
}

.mat-fab-bottom-right {
  position: absolute !important;
  right: 30px;
  bottom: 0px;
  background-color: $blue-color !important;
  z-index: 1;
}

.mat-fab-bottom-right .mat-button-wrapper {
  padding-top: 7px !important;
}

.mat-fab-no-elements {
  background-color: $blue-color !important;
  margin-left: 15px !important;
}

.mat-fab-no-elements .mat-button-wrapper {
  padding-top: 7px !important;
}

.second-float-button {
  right: 80px;
}

.marginLeft15 {
  margin-left: 15px;
}

.marginRight15 {
  margin-right: 15px;
}

.paddingRight15 {
  padding-right: 15px;
}

.paddingLeft15 {
  padding-left: 15px;
}

.text-align-center {
  text-align: center;
}

.loader-list {
  margin: 0 auto;
  margin-top: 10px;
}

.selectable {
  cursor: pointer;
}

.inline {
  display: inline;
}

.mytooltipcontainer {
  padding: 15px !important;
  max-width: 400px !important;
}

.mytooltipcontainer ul {
  text-align: left;
}

.upper-text {
  text-transform: uppercase;
}

.edit-label {
  margin-bottom: 0;
  text-align: left;
  width: 100%;
  font-size: 0.65rem;
  margin-left: 1.5rem;
  font-weight: 600;
  color: $grey-color;
  text-transform: uppercase;
}

.banner-container {
  background-color: white;
  border-radius: 15px;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
}

.banner-left-content {
  padding: 30px 30px 30px 50px;
}

.banner-left-content-container {
  max-width: 600px;
}

.banner-right-content {
  padding: 50px;
}

.banner-image {
  height: 300px;
}

.sdf-button {
  height: 40px;
}

.banner-title {
  //width: 330px;
  padding-bottom: 30px;
}

.details-modal .mat-dialog-container {
  background-color: #f8f8fa;
  padding: 15px !important;
}

.banner-message {
  font-size: 22px;
  color: grey;
  width: 400px;
}

.badge-delete-image {
  position: absolute;
  margin-left: 65px;
  margin-top: -8px;
  background-color: #cbcbcb;
  border-radius: 10px;
  font-size: 18px;
  width: 20px !important;
  color: #636363;
  height: 20px !important;
  padding-left: 1px;
  padding-top: 1px;
  cursor: pointer;
}

.title-toolbar {
  display: grid; justify-content: space-between; align-items: center; grid-template-columns: 1fr 40px;
}

.close-button {
  background-color: transparent;
  color: black;
}

.dialog-content {
  padding: 10px;
}

.dialog-title {
  text-align: center;
  font-size: 2.5rem;
  color: $orange-color;
  padding-top: 1.3rem;
  padding-bottom: 2.5rem;
  font-weight: 700;
}

.mat-step-icon-state-edit,
.mat-step-icon-selected {
  color: white !important;
}

.mat-vertical-content-container {
  margin-left: 10px !important;
}

.mat-vertical-stepper-header {
  padding-left: 0px !important;
}

mat-progress-bar .mat-progress-bar-buffer {
  //background: $blue-color;
  border-radius: 4px;
}

.mat-radio-label-content {
  width: fit-content !important;
}

.mat-radio-button {
  width: 100% !important;
}

.mat-horizontal-content-container {
  overflow: auto !important;
  padding: 0 3% 24px 3% !important;
}

.mat-mdc-header-cell {
  font-weight: bold !important;
}

// .icon-form-group .mat-form-field-appearance-legacy .mat-form-field-wrapper {
//   padding-bottom: 0 !important;
// }
// .icon-form-group .mat-form-field-appearance-legacy .mat-form-field-underline {
//   bottom: 0 !important;
// }
.mat-dialog-container > .ng-star-inserted {
  padding: 0;
}

.maintenance-stepper .mat-mdc-form-field-prefix .mat-icon-button {
  width: 2.8em !important;
  text-align: left !important;
}

// map animation on the Vehicle-Details
.gps_ring {
  border: 3px solid greenyellow;
  margin-top: 3px;
  margin-left: 10px;
  align-content: center;
  -webkit-border-radius: 40px;
  height: 18px;
  width: 18px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  /*opacity: 0.0*/
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 3px !important;
  display: grid;
  align-items: center;
  justify-content: center;
}
.dense {

  .mat-mdc-form-field-infix {
    // border-top: none;
  }

  &.mat-mdc-form-field-outline-gap {
    width: 0 !important;
  }

  &.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: .5em;
  }

  &.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline, .mat-mdc-form-field-suffix {
    top: 0.15em !important;
  }

}

.borderless-input {
  .mat-mdc-form-field-outline {
    * {
      border: none !important;
    }
  }

  box-shadow: 0px 2px 6px 0px #00000026;
  background: white;

  &.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline, .mat-mdc-form-field-suffix {
    height: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    top: 0 !important;

  }
}

.no-label {
  &.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: .25em !important;
  }
}

.no-hint {

  .mat-mdc-form-field-bottom-align {
    padding: 0;
    margin: 0;
    display: none;
  }

}

.full-width {
  width: 100% !important;
}

.display-none {
  display: none !important;
}

.column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.row {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  margin: 0;
  padding: 0;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;

  * {
    width: initial;
  }
}

.mat-mdc-form-field-infix {
  padding: 0 1rem !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: end;

  input {
    padding-bottom: 8px !important;
  }
}

.mdc-text-field {
  width: 100% !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.flat {
  .mat-mdc-text-field-wrapper, .mat-mdc-form-field-focus-overlay {
    background: transparent !important;
  }

  .mdc-line-ripple {
    display: none !important;
  }
}

.no-pad {
  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }
}

.mat-mdc-button {
  color: inherit !important;
}

.mdc-button.mat-primary {
  color: white !important;

}

.mat-mdc-button.mat-dark-primary {
  color: white !important;

}

.mat-mdc-icon-button {
  color: inherit !important;
  font-size: inherit !important;
  --mdc-icon-button-state-layer-size: calc(1em + 12px * 2) !important;

  svg {
    width: 1em !important;
    height: 1em !important;
  }
}
.mantine-Popover-dropdown{
  z-index:999!important;
}

.leaflet-control-attribution {
  display: none !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}
.rotate-90 {
  transform: rotate(90deg) !important;
}
.rotate-270 {
  transform: rotate(270deg) !important;
}
.rotate-45 {
  transform: rotate(45deg) !important;
}
.rotate-45ccw {
  transform: rotate(-45deg) !important;
}
.rotate-216 {
  transform: rotate(216deg) !important;
}


