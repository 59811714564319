$background-color: #F8F8FA;
$red-color: #FA090A;
$grey-color: #666466;
$black-color: #000000;
$light-grey-color: #E2E2E2;
$blue-color: #5F9AFF;
$orange-color: #FA8C17;
$header-height: 80px;
$sidebar-width: 200px;
$menu-height: 70px;
$margin5: 5px;
$margin15: 15px;
$margin30: 30px;
$gray: #c8c8c8;


$primary-color: #FF8C17;
$darken-primary-color: #C8C8C8;
$lighten-primary-color: hsla(30, 100%, 90%, 1);
$primary-125: hsla(25, 83%, 49%, 1);
$primary-150: hsla(20, 83%, 44%, 1);
$primary-175: hsla(19, 83%, 38%, 1);
$primary-75: hsla(30, 100%, 62%, 1);
$primary-50: hsla(30, 100%, 75%, 1);
$primary-25: hsla(30, 100%, 82%, 1);
$primary-10: hsla(30, 100%, 97%, 1);
$positive-color  : #21BA45;
$darken-positive-color  : #0f5921;
$negative-color  : #C10015;
$info-color      : #31CCEC;
$warning-color   : #F2C037;


//$primary-color: #178BFF;
//$darken-primary-color: #0E5499;
//$lighten-primary-color: #C9E4FF;
//$primary-125: #157DE6;
//$primary-150: #136FCC;
//$primary-175: #1061B3;
//$primary-75: #3D9EFF;
//$primary-50: #7DBEFF;
//$primary-25: #A3D1FF;
//$primary-10: #F0F7FF;
